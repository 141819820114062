export default Vue.component('ts-product-sticker', {
    template: '<div class="row">\n' +
        '        <div class="col-12">\n' +
        '            <div id="ts_product_sticker"></div>\n' +
        '        </div>\n' +
        '    </div>',
    props: {
        tsSettings: {
            type    : Object,
            default :() => ({})
        },
        tsLocale: {
            type    : String,
            default : ''
        },
        itemId: {
            type    : Number,
            default : 0
        },
    },
    mounted() {
        this.initSticker();
    },
    methods: {
        initSticker() {

            if(this.tsSettings.display_mode === 'expert') {

                let customCode = this.tsSettings.product_sticker_custom_code;

                customCode     = customCode.replace('[TSID]', this.tsSettings.tsid);
                customCode     = customCode.replace('[SKU]', ''+this.itemId);
                customCode     = customCode.replace(new RegExp('_tsProductReviewsConfig', 'g'), 'window._tsProductReviewsConfig');
                eval(customCode);

                if (
                    this.tsSettings.product_sticker_jquery_selector !== '' &&
                    this.tsSettings.product_sticker_jquery_selector !== '#ts_product_sticker'
                ){
                    document.querySelector(this.tsSettings.product_sticker_jquery_selector)
                        .appendChild(document.getElementById('ts_product_sticker'));
                }

            }else {
                if(this.tsSettings.tsid !== '' && typeof this.tsSettings.tsid !== 'undefined') {

                    window._tsProductReviewsConfig = {
                        tsid               : this.tsSettings.tsid + '',
                        sku                : [''+this.itemId],
                        variant            : 'productreviews',
                        borderColor        : this.tsSettings.border_color.toUpperCase(),
                        backgroundColor    : this.tsSettings.background_color.toUpperCase(),
                        locale             : this.tsLocale,
                        starColor          : this.tsSettings.star_color_product_sticker.toUpperCase(),
                        commentHideArrow   : 'false',
                        commentBorderColor : '#dad9d5',
                        richSnippets       : this.tsSettings.enable_rich_snippets === '1' ? 'on' : 'off',
                        starSize           : '15px',
                        ratingSummary      : 'false',
                        maxHeight          : '1200px',
                        hideEmptySticker   : 'false',
                        filter             : 'true',
                        element            : '#ts_product_sticker',
                        introtext          : 'What our customers say about us:'
                    };

                    // if(!document.querySelector('script[src="//widgets.trustedshops.com/reviews/tsSticker/tsProductSticker.js"]')) {

                        let scripts = document.getElementsByTagName('SCRIPT'),
                            me      = scripts[scripts.length - 1];

                        let _ts     = document.createElement('SCRIPT');
                        _ts.type    = 'text/javascript';
                        // _ts.async   = true;
                        _ts.src     = '//widgets.trustedshops.com/reviews/tsSticker/tsProductSticker.js';

                        me.parentNode.insertBefore(_ts, me);
                        window._tsProductReviewsConfig.script = _ts;
                    // }else{
                    //      window._tsProductReviewsConfig.script = document.querySelector('script[src="//widgets.trustedshops.com/reviews/tsSticker/tsProductSticker.js"]');
                    // }
                }else {
                    throw new Error('No Trusted Shops ID was configured in the backend. Cannot load review sticker!');
                }
            }
        }
    }
});
