export default Vue.component('ts-product-sticker-summary', {
    template: '<div class="row">\n' +
        '        <div class="col-12">\n' +
        '            <div id="ts_product_widget"></div>\n' +
        '        </div>\n' +
        '    </div>',
    props: {
        tsSettings: {
            type    : Object,
            default :() => ({})
        },
        itemId: {
            type    : Number,
            default : 0
        },
    },
    mounted() {
        this.initSticker();
    },
    methods: {
        initSticker() {

            // if(!document.querySelector('script[src="//widgets.trustedshops.com/reviews/tsSticker/tsProductStickerSummary.js"]')) {

                let that    = this;
                let scripts = document.getElementsByTagName('SCRIPT'),
                    me      = scripts[scripts.length - 1];

                let _ts     = document.createElement('SCRIPT');
                _ts.type    = 'text/javascript';
                // _ts.async   = true;
                _ts.src     = '//widgets.trustedshops.com/reviews/tsSticker/tsProductStickerSummary.js';

                me.parentNode.insertBefore(_ts, me);

                // only required for IE <9
                if(_ts.readyState) {
                    _ts.onreadystatechange = () => {
                        if (_ts.readyState === 'loaded' || _ts.readyState === 'complete') {
                            _ts.onreadystatechange = null;
                            that.createProductStickerSummary();
                        }
                    };
                }else {
                    _ts.onload = () => {
                        that.createProductStickerSummary();
                    };
                }
            // }else{
            //     this.createProductStickerSummary();
            // }
        },
        createProductStickerSummary() {
            if(this.tsSettings.display_mode === 'expert') {
                this.stickerSummaryCustom();
            }else{
                this.stickerSummary();
            }
        },
        stickerSummary() {

            if(this.tsSettings.tsid !== '' && typeof this.tsSettings.tsid !== 'undefined') {

                let summaryBadge = new productStickerSummary();

                summaryBadge.showSummary({
                    'tsId'              : this.tsSettings.tsid + '',
                    'sku'               : [''+this.itemId],
                    'element'           : '#ts_product_widget',
                    'starColor'         : this.tsSettings.star_color_product_widget.toUpperCase(),
                    'starSize'          : this.tsSettings.star_size + 'px',
                    'fontSize'          : this.tsSettings.font_size + 'px',
                    'showRating'        : 'true',
                    'scrollToReviews'   : 'false',
                    'enablePlaceholder' : this.tsSettings.hide_empty_star_ratings === '0' ? 'true' : 'false'
                });
            }else {
                throw new Error('No Trusted Shops ID was configured in the backend. Cannot load review sticker summary!');
            }
        },
        stickerSummaryCustom() {

            let customCode = this.tsSettings.product_widget_custom_code;

            customCode     = customCode.replace('[TSID]', this.tsSettings.tsid);
            customCode     = customCode.replace('[SKU]', ''+this.itemId);
            eval(customCode);
            if (
                this.tsSettings.product_widget_jquery_selector !== '' &&
                this.tsSettings.product_widget_jquery_selector !== '#ts_product_widget'
            ){
                document.querySelector(this.tsSettings.product_widget_jquery_selector)
                    .appendChild(document.getElementById('ts_product_widget'));
            }
        }
    }
});
